<template>
  <div>
    <div v-if="loading" class="text-md-center">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <v-card v-if="!loading" class="md-card-contacto">
      <v-card-text>
        <p class="v-label">{{ $t('contact.texto') }}</p>
        <form class="form_datos_personales">
          <v-layout wrap>
            <v-flex xs12 sm6 lg6 class="d-none">
              <v-text-field
                v-model="contact.name"
                class="mr-2"
                :label="$t('common.name') + '*'"
                type="text"
                append-icon="mdi-account-circle"
                required
              />
            </v-flex>
            <v-flex xs12 sm6 lg6 class="d-none">
              <v-text-field
                v-model="contact.email"
                class="mr-2"
                :label="$t('message.email') + '*'"
                append-icon="mdi-at"
                type="mail"
                required
              />
            </v-flex>
            <v-flex xs12 sm12 lg12>
              <v-text-field
                v-model="contact.subject"
                class="mr-2"
                :label="$t('mensajeria.motivo') + ' *'"
                append-icon="mdi-text-subject"
                type="mail"
              />
            </v-flex>
            <v-flex xs12 sm12 lg12>
              <v-textarea
                v-model="contact.message"
                style="width: 98%; margin-left: 4px;"
                :label="$t('mensajeria.mensaje') + ' *'"
                rows="4"
              />
            </v-flex>
            <v-spacer />
            <v-flex xs12 class="text-right">
              <v-btn xs12 :loading="loadingSend" :color="customTemplate ? 'primary' : 'info'" @click="send()">{{
                $t('mensajeria.enviar')
              }}</v-btn>
            </v-flex>
          </v-layout>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getProfileUrl, enviarPreguntaUrl, getHeader } from '../config/config';

export default {
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    mode: '',
    contact: {},
    disabledButton: false,
    loadingSend: false,
    customTemplate:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
        ? true
        : false,
  }),

  created() {
    this.$http.post(getProfileUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
      this.contact = response.data;
      this.contact.name += ' ' + this.contact.apellidos;
      this.contact.message = '';
      this.contact.subject = '';
      this.loading = false;
    });
  },

  methods: {
    validateForm() {
      let errorText = '';
      const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (this.contact.name.length < 3) {
        errorText = this.$t('patient.full_name_required');
      }

      if (this.contact.email === '') {
        errorText = this.$t('errors.email_required');
      } else if (!regex.test(this.contact.email)) {
        errorText = this.$t('errors.email_invalid');
      }

      if (this.contact.message.length < 3) {
        errorText = this.$t('contact.mensaje_obligatorio');
      }

      if (this.contact.subject.length < 3) {
        errorText = this.$t('contact.motivo_obligatorio');
      }

      if (errorText !== '') {
        this.$toast.error(errorText);
      }

      return errorText === '';
    },

    send() {
      if (this.validateForm()) {
        this.loadingSend = true;
        this.disabledButton = true;
        this.$http
          .post(enviarPreguntaUrl, { id: this.authUser.id, contact: this.contact }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.$toast.success(this.$t('contact.enviado_correctamente'));
              this.contact.message = '';
              this.contact.subject = '';
            } else {
              this.$toast.error(this.$t('errors.try_again'));
            }
            this.loadingSend = false;
            this.disabledButton = true;
          });
      }
    },
  },
};
</script>
<style scoped lang="css">
.md-card-contacto {
  width: 96%;
  margin: 0px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.form_datos_personales {
  margin-top: 30px;
}
</style>
